"use strict";

function RavenLogger() {
    var self = this;

    self.initialize = function () {
        Raven.config('https://83fd16994c554fff9e15a37c3381aef4@sentry.io/108704', {
            release: '1.9.2.2',
            ignoreErrors: ['dupes', 'gCrWeb.autofill.extractForms', 'greasemonkey', 'opp_applicable_turn', 'plugin.setSuspendState', 'InvalidStateError', '$rootScope:inprog']
        }).install();
    };

    self.setUserDetails = function (id, name) {
        Raven.setUserContext({
            email: name + "@webclient",
            username: name,
            id: id
        });
    };

    self.write = function (message) {
        Raven.captureMessage(message);
    };
}

function ConsoleLogger() {
    var self = this;

    self.initialize = function () {
        // Do nothing
    };

    self.setUserDetails = function (id, name) {
        // Do nothing
    };

    self.write = function (message) {
        console.log(message);
    };
}

function catchAll(f) {
    try {
        f();
    } catch (e) {
        // Do nothing
    }
}