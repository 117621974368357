"use strict";

function Stopwatch() {
    var self = this;
    var startTime = -1;
    var isRunning = false;

    self.start = function () {
        if (isRunning) return;
        startTime = new Date().getTime();
        isRunning = true;
    };

    self.stop = function () {
        if (!isRunning) return -1;
        isRunning = false;
        return new Date().getTime() - startTime;
    };
}