"use strict";

function checkVersion() {
    var rv = -1; // Return value assumes failure.

    if (navigator.appName == 'Microsoft Internet Explorer') {

        var ua = navigator.userAgent,
            re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");

        if (re.exec(ua) !== null) {
            rv = parseFloat(RegExp.$1);
        }
    }

    return rv;
}

function redirectToBrowserNotSupported() {
    location.href = "browser-not-supported.html";
}

function isIE() {
    if (navigator.appName === 'Microsoft Internet Explorer') return true;
    if (navigator.appName.indexOf('Explorer') > -1) return true;
    if (navigator.appCodeName === 'Microsoft') return true;
    return !!window.MSInputMethodContext && !!document.documentMode;
}

if (checkVersion() !== -1 || isIE()) redirectToBrowserNotSupported();