"use strict";

var WINDOWS_GECKO = {
    FULL_TITLE_LENGTH_OVER_24_TOP_OFFSET: 22,
    FULL_TITLE_LENGTH_OVER_14_TOP_OFFSET: 17,
    FULL_TITLE_LENGTH_OVER_12_TOP_OFFSET: 16,
    FULL_TITLE_LENGTH_OTHER_TOP_OFFSET: 16,
    FULL_BASIC_TREASURE_TITLE_TOP_OFFSET: 25,
    FULL_BASIC_OTHER_TITLE_TOP_OFFSET: 22,

    FULL_TYPES_LENGTH_UNDER_13_OFFSET: 20,
    FULL_TYPES_LENGTH_UNDER_14_OFFSET: 24,
    FULL_TYPES_LENGTH_UNDER_16_OFFSET: 30,
    FULL_TYPES_LENGTH_UNDER_20_OFFSET: 35,
    FULL_TYPES_LENGTH_UNDER_26_OFFSET: 40,
    FULL_TYPES_LENGTH_UNDER_28_OFFSET: 45,
    FULL_TYPES_LENGTH_OTHER_OFFSET: 50,

    MINI_TITLE_LENGTH_OVER_14_TOP_OFFSET: 20,
    MINI_TITLE_LENGTH_OVER_11_TOP_OFFSET: 19,
    MINI_TITLE_LENGTH_OTHER_TOP_OFFSET: 17,
    MINI_TITLE_LENGTH_OTHER_FONTSIZE: 1.8,

    MINI_BASIC_TREASURE_TITLE_TOP_OFFSET: 15,
    MINI_CURSE_TITLE_TOP_OFFSET: 24,
    MINI_BASIC_VICTORY_TITLE_TOP_OFFSET: 16,

    MINI_LANDSCAPE_TITLE_LENGTH_OVER_14_TOP_OFFSET: 228,
    MINI_LANDSCAPE_TITLE_LENGTH_OVER_12_TOP_OFFSET: 226,
    MINI_LANDSCAPE_TITLE_LENGTH_OTHER_TOP_OFFSET: 216,

    FULL_LANDSCAPE_TITLE_LENGTH_OVER_19_TOP_OFFSET: 9,
    FULL_LANDSCAPE_TITLE_LENGTH_OVER_16_TOP_OFFSET: 7,
    FULL_LANDSCAPE_TITLE_LENGTH_OVER_14_TOP_OFFSET: 6,
    FULL_LANDSCAPE_TITLE_LENGTH_OVER_12_TOP_OFFSET: 4,
    FULL_LANDSCAPE_TITLE_LENGTH_OTHER_TOP_OFFSET: 4,

    FULL_COST_TEXT_TOP: 12,

    MINI_COIN_COST_TEXT_TOP: 12,
    MINI_EVENT_COST_TEXT_TOP: 10,
    COUNTER_TEXT_TOP_OFFSET_PERCENTAGE: 14,
    COUNTER_CONTAINER_TOP_OFFSET_PERCENTAGE: 50,

    PILE_DEBT_AMOUNT_TOP: 24,

    CARD_TEXT_AMOUNT_TOP: 8,
    CARD_TEXT_DEBT_AMOUNT_TOP: 48,

    CARD_TEXT_SINGLE_LINE_TOP_OFFSET: 73,
    CARD_TEXT_DOUBLE_LINE_TOP_OFFSET: 55,
    CARD_TEXT_TRIPLE_LINE_TOP_OFFSET: 47,
    CARD_TEXT_QUADRUPLE_LINE_TOP_OFFSET: 29,
    CARD_TEXT_QUINTUPLE_LINE_TOP_OFFSET: 20,
    CARD_TEXT_SEXTUPLE_LINE_TOP_OFFSET: 10,
    CARD_TEXT_SEPTUPLE_LINE_TOP_OFFSET: 5,

    LANDSCAPE_TEXT_SINGLE_LINE_TOP_OFFSET: 14,
    LANDSCAPE_TEXT_DOUBLE_LINE_TOP_OFFSET: 0,
    LANDSCAPE_TEXT_TRIPLE_LINE_TOP_OFFSET: 0
};

var WINDOWS_WEBKIT = {
    FULL_TITLE_LENGTH_OVER_24_TOP_OFFSET: 22,
    FULL_TITLE_LENGTH_OVER_14_TOP_OFFSET: 17,
    FULL_TITLE_LENGTH_OVER_12_TOP_OFFSET: 16,
    FULL_TITLE_LENGTH_OTHER_TOP_OFFSET: 16,
    FULL_BASIC_TREASURE_TITLE_TOP_OFFSET: 28,
    FULL_BASIC_OTHER_TITLE_TOP_OFFSET: 24,

    FULL_TYPES_LENGTH_UNDER_13_OFFSET: 20,
    FULL_TYPES_LENGTH_UNDER_14_OFFSET: 24,
    FULL_TYPES_LENGTH_UNDER_16_OFFSET: 30,
    FULL_TYPES_LENGTH_UNDER_20_OFFSET: 35,
    FULL_TYPES_LENGTH_UNDER_26_OFFSET: 40,
    FULL_TYPES_LENGTH_UNDER_28_OFFSET: 45,
    FULL_TYPES_LENGTH_OTHER_OFFSET: 50,

    MINI_TITLE_LENGTH_OVER_14_TOP_OFFSET: 20,
    MINI_TITLE_LENGTH_OVER_11_TOP_OFFSET: 19,
    MINI_TITLE_LENGTH_OTHER_TOP_OFFSET: 17,
    MINI_TITLE_LENGTH_OTHER_FONTSIZE: 1.8,

    MINI_BASIC_TREASURE_TITLE_TOP_OFFSET: 18,
    MINI_CURSE_TITLE_TOP_OFFSET: 24,
    MINI_BASIC_VICTORY_TITLE_TOP_OFFSET: 18,

    MINI_LANDSCAPE_TITLE_LENGTH_OVER_14_TOP_OFFSET: 228,
    MINI_LANDSCAPE_TITLE_LENGTH_OVER_12_TOP_OFFSET: 226,
    MINI_LANDSCAPE_TITLE_LENGTH_OTHER_TOP_OFFSET: 216,

    FULL_LANDSCAPE_TITLE_LENGTH_OVER_19_TOP_OFFSET: 9,
    FULL_LANDSCAPE_TITLE_LENGTH_OVER_16_TOP_OFFSET: 7,
    FULL_LANDSCAPE_TITLE_LENGTH_OVER_14_TOP_OFFSET: 6,
    FULL_LANDSCAPE_TITLE_LENGTH_OVER_12_TOP_OFFSET: 4,
    FULL_LANDSCAPE_TITLE_LENGTH_OTHER_TOP_OFFSET: 4,

    FULL_COST_TEXT_TOP: 12,

    MINI_COIN_COST_TEXT_TOP: 12,
    MINI_EVENT_COST_TEXT_TOP: 12,
    COUNTER_TEXT_TOP_OFFSET_PERCENTAGE: 15,
    COUNTER_CONTAINER_TOP_OFFSET_PERCENTAGE: 50,

    PILE_DEBT_AMOUNT_TOP: 24,

    CARD_TEXT_AMOUNT_TOP: 8,
    CARD_TEXT_DEBT_AMOUNT_TOP: 48,

    CARD_TEXT_SINGLE_LINE_TOP_OFFSET: 73,
    CARD_TEXT_DOUBLE_LINE_TOP_OFFSET: 55,
    CARD_TEXT_TRIPLE_LINE_TOP_OFFSET: 47,
    CARD_TEXT_QUADRUPLE_LINE_TOP_OFFSET: 29,
    CARD_TEXT_QUINTUPLE_LINE_TOP_OFFSET: 20,
    CARD_TEXT_SEXTUPLE_LINE_TOP_OFFSET: 10,
    CARD_TEXT_SEPTUPLE_LINE_TOP_OFFSET: 5,

    LANDSCAPE_TEXT_SINGLE_LINE_TOP_OFFSET: 14,
    LANDSCAPE_TEXT_DOUBLE_LINE_TOP_OFFSET: 0,
    LANDSCAPE_TEXT_TRIPLE_LINE_TOP_OFFSET: 0
};