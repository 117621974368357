"use strict";

var os = -1;

function OperatingSystem(name) {
    var self = this;
    self.name = name;
    self.toString = function () {
        return self.name;
    };
}

var OS = {
    WINDOWS: new OperatingSystem("Windows"),
    MAC: new OperatingSystem("Mac"),
    LINUX: new OperatingSystem("Linux"),
    IOS: new OperatingSystem("iOS"),
    ANDROID: new OperatingSystem("Android")
};

function getOS() {
    var s = window.navigator.platform;
    if (s.indexOf("Win") > -1) {
        return OS.WINDOWS;
    } else if (s.indexOf("Mac") > -1) {
        return OS.MAC;
    } else if (s.indexOf("Linux") > -1) {
        return OS.LINUX;
    } else if (s.indexOf("iP") > -1) {
        return OS.IOS;
    } else if (s.indexOf("Android") > -1) {
        return OS.ANDROID;
    } else {
        return OS.WINDOWS;
    }
}

function RenderingEngine(name) {
    var self = this;
    self.name = name;
    self.toString = function () {
        return self.name;
    };
}

var ENGINE = {
    GECKO: new RenderingEngine("Gecko"),
    WEBKIT: new RenderingEngine("Webkit"),
    // BLINK: new RenderingEngine("Blink"),
    EDGE: new RenderingEngine("Edge")

};

function getRenderingEngine() {
    var s = platform.layout;
    if (s.indexOf("Gecko") > -1) {
        return ENGINE.GECKO;
    } else if (s.indexOf("Edge") > -1) {
        return ENGINE.EDGE;
    } else {
        return ENGINE.WEBKIT;
    }
}

function getPlatform() {
    os = getOS();
    var engine = getRenderingEngine();
    setTextEncoderUse(os);
    if (os === OS.WINDOWS) {
        if (engine === ENGINE.GECKO) {
            return WINDOWS_GECKO;
        } else if (engine === ENGINE.WEBKIT) {
            return WINDOWS_WEBKIT;
        } else if (engine === ENGINE.EDGE) {
            redirectToBrowserNotSupported();
            return WINDOWS_WEBKIT;
        }
    } else if (os === OS.MAC) {
        if (engine === ENGINE.GECKO) {
            return MAC_GECKO;
        } else if (engine === ENGINE.WEBKIT) {
            return MAC_WEBKIT;
        }
    } else if (os === OS.LINUX) {
        if (engine === ENGINE.GECKO) {
            return LINUX_GECKO;
        } else if (engine === ENGINE.WEBKIT) {
            return LINUX_WEBKIT;
        }
    } else if (os === OS.IOS) {
        return MAC_WEBKIT;
    } else {
        console.log("This OS has no style customization, using WINDOWS_WEBKIT.");
        return WINDOWS_WEBKIT;
    }
}

var shouldUseTextEncoder = true;

function setTextEncoderUse(os) {
    if (os === OS.IOS || platform.name === 'Safari') shouldUseTextEncoder = false;
}