"use strict";

var DEBUG_URL_PARAMETERS = {};

function setDebugUrlParameters() {

    var assignments = window.location.search.substring(1).split("&");

    assignments.forEach(function (assignment) {

        var key_value = assignment.split('=');

        DEBUG_URL_PARAMETERS[key_value[0]] = key_value[1];
    });
}

function useDebugUrlParameters() {

    return isDebug || VERSION.startsWith('TEST') || VERSION.startsWith('DEVELOPMENT');
}

if (useDebugUrlParameters()) {
    setDebugUrlParameters();
};