"use strict";
"use strict";

var isDebug = false;
var VERSION = "1.9.2.2";
var PLATFORM = getPlatform();
var webclient = angular.module('Webclient', ['ngAnimate', 'ngSanitize', 'cfp.hotkeys', 'ui.grid', 'ui.grid.selection', 'ngMaterial', 'ngRaven', 'ngRoute', 'ngDropdowns', 'ngTouch']);

var shouldLogDigests = false;
var shouldShowAngularStats = false;

webclient.config(['$compileProvider', '$locationProvider', function ($compileProvider, $locationProvider) {
    $compileProvider.debugInfoEnabled(isDebug);
    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
    });
}]);

webclient.run(function () {
    if (isDebug) checkQuestionDescriptions();
    if (isDebug && shouldShowAngularStats) showAngularStats();
});

// Inject services you want to be available from the start here
webclient.run(['$animate', 'preferences', 'soundService', 'relations', function ($animate, preferences, soundService, relations) {
    $animate.enabled(true);
    soundService.initialize();
}]);

var numberOfDigests = 0;

webclient.run(['$rootScope', function ($rootScope) {
    if (!isDebug) return;
    $rootScope.$watch(function () {
        if (!shouldLogDigests) return;
        numberOfDigests++;
        timeLog('Digest #', numberOfDigests);
    });
}]);

webclient.run(['$rootScope', function ($rootScope) {
    $rootScope.safeApply = function safeApply(operation) {
        var phase = this.$root.$$phase;
        if (phase !== '$apply' && phase !== '$digest') {
            this.$apply(operation);
            return;
        }

        if (operation && typeof operation === 'function') operation();
    };
}]);

var safeDigest = function safeDigest(scope) {
    var counts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;

    var phase = scope.$root.$$phase;
    if (phase !== '$apply' && phase !== '$digest') {
        scope.$digest();
        return;
    }
};

var errorHandler = isDebug ? new ConsoleLogger() : new RavenLogger();
errorHandler.initialize();
var stopwatch = new Stopwatch();

var whenWindowLoaded = new Promise(function (resolve) {
    window.addEventListener("load", resolve);
});

function checkQuestionDescriptions() {
    for (var q in QuestionIds) {
        try {
            var d = LANGUAGE.getStory[QuestionIds[q]];
            var s = d[0];
        } catch (e) {
            console.log("Can't find question description for ", q);
        }
    }
}