"use strict";

function WaitAtMost(time) {
    var self = this;

    self.whileDoing = function (f) {
        return new Promise(function (resolve) {
            var hasResolved = false;
            var finish = function finish() {
                if (!hasResolved) {
                    timeLog("Proceeding after async tasks were finished.");
                    resolve();
                    hasResolved = true;
                }
            };
            f().then(finish);
            setTimeout(function () {
                if (!hasResolved) {
                    timeLog("Proceeding before async tasks were finished (waiting limit reached).");
                    resolve();
                    hasResolved = true;
                }
            }, time);
        });
    };
}