"use strict";

var isLoadingGame = false;
var isLoadingLobby = false;
var rightClickHideEnabled = false;
var publicPageDisplay = void 0;

webclient.controller('PageDisplayController', ['$rootScope', '$scope', 'connection', 'tableService', 'gameBroadcaster', 'inboxService', 'metaBroadcaster', 'preferences', '$timeout', 'reconnect', 'serverMessenger', 'meta', 'metaStudyService', function ($rootScope, $scope, connection, tableService, gameBroadcaster, inboxService, metaBroadcaster, preferences, $timeout, reconnect, serverMessenger, meta, metaStudyService) {
    var pageDisplay = this;
    pageDisplay.lobbyStyle = "";
    pageDisplay.gamePageIsReady = false;
    pageDisplay.lobbyPageIsReady = false;
    pageDisplay.shouldKillGame = false;
    pageDisplay.shouldShowKickButton = false;

    var updateVH = function updateVH() {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
    };
    window.addEventListener('resize', updateVH);

    if (isDebug) publicPageDisplay = this;

    var loadingAssets = ["images/dom-icon-black.png"];
    var lobbyAssets = ["images/large/adventures.webp", 'images/materials/gold_button.jpg', 'images/materials/stone_button.png', 'images/materials/stone_button_small.png', 'images/materials/copper_button.jpg', 'images/materials/copper_button_round.png', 'images/materials/copper_button_small.png', 'images/cards/templates/back.jpg', 'images/cards/templates/back-mini.jpg', 'images/elements/add.png', 'images/elements/add-bot.png', 'images/elements/bot.png', 'images/elements/dice.png', 'images/elements/exit.png', 'images/elements/heart-small.png', 'images/elements/heart-small-dark.png', 'images/elements/heart-small-left.png', 'images/elements/heart-small-right.png', 'images/elements/indicator-off.png', 'images/elements/indicator-on.png', 'images/elements/lightning-small.png', 'images/elements/lightning-small-dark.png', 'images/elements/minus.png', 'images/elements/play.png', "images/elements/coin.png", "images/elements/potion.png", "images/elements/debt_130.png"];
    var profileAssets = ["images/large/prosperity.webp"];
    var scoreAssets = ["images/large/survivors.webp", "images/large/triumphal-arch.webp", "images/large/arena.webp"];
    var loginAssets = ["images/spear-left.png", "images/spear-right.png", 'images/large/title.webp', 'images/large/base.webp', 'images/materials/copper_button.jpg', 'images/materials/copper_button_small.png'];
    var cardAssets = Object.values(CardNames).filter(function (c) {
        return c.name !== 'unused';
    }).map(function (n) {
        return getFullArtURL(n);
    });
    var gameAssets = ['images/elements/red-cross.png', 'images/elements/yellow-cross.png', "images/elements/coin.png", "images/elements/potion.png", "images/elements/debt_130.png"];
    var backgroundAssets = ["images/large/inheritance.webp", "images/large/swamp-hag.webp", "images/large/haunted-woods.webp", "images/large/dungeon.webp", "images/large/rebuild.webp", "images/large/adventures.webp", "images/large/hinterlands.webp", "images/large/feodum.webp", "images/large/base.webp", "images/large/dark-wood.webp", "images/large/trail.webp", "images/large/seaside.webp", "images/large/architects-guild.webp", "images/large/commerce.webp", "images/large/distant-shore.webp", "images/large/anvil.webp", "images/large/seize-the-day.webp", "images/large/the-skys-gift.webp", "images/large/way-of-the-horse.webp", "images/large/gamble.webp", "images/large/abundance.webp", "images/large/maelstrom.webp", "images/large/reckless.webp", "images/large/spell-scroll.webp", "images/large/swamp-shacks.webp"];
    var gameFonts = ["Minion", "Minion-Bold", "Times New Roman", "TrajanPro-Bold", "Montserrat"];

    pageDisplay.hideAllPages = function () {
        pageDisplay.shouldShowLoadingPage = false;
        hideAllNonLoadingPages();
    };

    pageDisplay.showLoadingPage = function () {
        pageDisplay.hideAllPages();
        pageDisplay.shouldShowLoadingPage = true;
    };

    pageDisplay.showLoginPage = function () {
        pageDisplay.hideAllPages();
        pageDisplay.shouldShowLoginPage = true;
    };

    pageDisplay.loadLoginPage = function () {
        pageDisplay.showLoadingPage();
        Preloader.loadAll(loadingAssets.concat(loginAssets).concat(lobbyAssets), function () {
            pageDisplay.showLoginPage();
            safeDigest($scope);
        });
    };

    var initialFontLoad = function initialFontLoad() {
        var fontsToLoad = [new FontFace("Raleway", "url(fonts/Raleway-Regular.ttf)", { "feature-settings": "lnum" }), new FontFace("Minion-Bold", "url(fonts/MinionPro-Bold.woff)"), new FontFace("TrajanPro-Bold", "url(fonts/TrajanPro3SemiBold.ttf)"), new FontFace("Times New Roman", "url(fonts/times.ttf)"), new FontFace("Montserrat", "url(fonts/Montserrat-SemiBold.ttf)")];

        var loadTimeout = null;
        var finishLoading = function finishLoading() {
            if (loadTimeout !== null) clearTimeout(loadTimeout);
            loadTimeout = null;
            pageDisplay.loadLoginPage();
        };
        var loadCount = 0;
        var postLoad = function postLoad(font) {
            document.fonts.add(font);
            loadCount++;
            if (loadCount === fontsToLoad.length) {
                finishLoading();
            }
        };
        loadTimeout = setTimeout(finishLoading, 1000);
        fontsToLoad.forEach(function (font) {
            font.load().then(postLoad(font));
        });
    };
    initialFontLoad();

    function hideAllNonLoadingPages() {
        pageDisplay.shouldShowLoginPage = false;
        pageDisplay.shouldShowLobbyPage = false;
        pageDisplay.shouldShowGamePage = false;
        pageDisplay.shouldShowScorePage = false;
        pageDisplay.shouldShowProfilePage = false;
        pageDisplay.lobbyPageIsReady = false;
        pageDisplay.gamePageIsReady = false;
    }

    pageDisplay.hideAllPages();

    pageDisplay.loadLobbyPage = function () {
        isLoadingLobby = true;
        timeLog("Loading lobby page.");
        var a = lobbyAssets;
        a.push.apply(a, scoreAssets);
        Preloader.loadAll(a, function () {
            hideAllNonLoadingPages();
            gameBroadcaster.sendStoredEvents();
            pageDisplay.showLobbyPage();
        });
    };

    pageDisplay.showLobbyPage = function () {
        pageDisplay.lobbyStyle = createBackgroundStyle(lobbyAssets[0]);
        pageDisplay.shouldShowLobbyPage = true;
        updateVH();
        pageDisplay.hasLoadedLobby();
        setTimeout(function () {
            safeDigest($rootScope);
        }, 0);
    };

    pageDisplay.showProfilePage = function () {
        pageDisplay.profileStyle = createBackgroundStyle(profileAssets[0]);
        pageDisplay.hideAllPages();
        pageDisplay.shouldShowProfilePage = true;
        updateVH();
    };

    pageDisplay.showGamePage = function () {
        var backgroundIndex = preferences.getUserPrefValue(UserPrefIds.PREFERRED_BACKGROUND);
        if (backgroundIndex === 0) {
            pageDisplay.gameStyle = "";
        } else {
            pageDisplay.gameStyle = createBackgroundStyle(backgroundAssets[backgroundIndex - 1]);
        }
        pageDisplay.hideAllPages();
        // pageDisplay.shouldShowLoadingPage = true;
        pageDisplay.shouldShowGamePage = true;
        pageDisplay.gamePageIsReady = true;
        updateVH();
    };

    pageDisplay.hasLoadedLogin = function () {
        catchAll(function () {
            return window.document.getElementById("username-input").focus();
        });
    };

    pageDisplay.hasLoadedGame = function () {
        timeLog("Game has loaded.");
        reconnect.gameLoaded();
        connection.gameLoaded();
        isLoadingGame = false;
        gameBroadcaster.sendStoredEvents();
        pageDisplay.shouldShowLoadingPage = false;
        pageDisplay.gamePageIsReady = true;
        profileLoadingTime();
        setTimeout(function () {
            $scope.$digest();
            gameBroadcaster.send(Events.GAME_PAGE_LOADED);
        }, 0);
    };

    $scope.$on(Events.SET_BACKGROUND, function (event, index) {
        if (index < 1) pageDisplay.gameStyle = "";
        if (index > backgroundAssets.length) index = backgroundAssets.length;
        preferences.setUserPrefValue(UserPrefIds.PREFERRED_BACKGROUND, index);
        if (index < 1) return;
        Preloader.load(backgroundAssets[index - 1], function () {
            pageDisplay.gameStyle = createBackgroundStyle(backgroundAssets[index - 1]);
            safeDigest($scope);
        });
    });

    pageDisplay.hasLoadedScore = function () {
        timeLog("has loaded score.");
        gameBroadcaster.send(Events.SCORE_PAGE_LOADED);
    };

    pageDisplay.hasLoadedLobby = function () {
        timeLog("Lobby has loaded.");
        isLoadingLobby = false;
        metaBroadcaster.sendStoredEvents();
        pageDisplay.shouldShowLoadingPage = false;
        pageDisplay.lobbyPageIsReady = true;
        metaBroadcaster.send(Events.LOBBY_PAGE_LOADED);
        $timeout(function () {
            $scope.$digest();
            pageDisplay.shouldLoadKingdomCreator = true;
        }, 0);
    };

    pageDisplay.loadScorePage = function () {
        var results = tableService.gameResult.playerResults;
        var myResult = results.find(function (r) {
            return r.playerId === meta.model.me.id;
        });
        var idx = 0;
        if (!isUndefined(myResult) && myResult.rank > 1) idx = 0;else if (!isUndefined(myResult)) idx = 1;else idx = 2;
        timeLog("loading score page.");
        pageDisplay.showLoadingPage();
        timeLog("loading score assets.");
        Preloader.load(scoreAssets[idx], function () {
            timeLog("showing score page.");
            $rootScope.safeApply(pageDisplay.showScorePage());
        });
    };

    pageDisplay.showScorePage = function () {
        var results = tableService.gameResult.playerResults;
        var myResult = results.find(function (r) {
            return r.playerId === meta.model.me.id;
        });
        if (!isUndefined(myResult) && myResult.rank > 1) pageDisplay.scoreStyle = createBackgroundStyle(scoreAssets[0]);else if (!isUndefined(myResult)) pageDisplay.scoreStyle = createBackgroundStyle(scoreAssets[1]);else pageDisplay.scoreStyle = createBackgroundStyle(scoreAssets[2]);
        pageDisplay.hideAllPages();
        pageDisplay.shouldShowScorePage = true;
        pageDisplay.scoreFocusedTab = "score-focus";
        pageDisplay.scoreRulesHaveChanged = false;

        serverMessenger.requestUpdate(UpdateTypes.FRIEND_LIST);
        updateVH();
    };

    pageDisplay.onMouseDown = function ($event) {
        if (!rightClickHideEnabled) return;
        if ($event.which !== 3) return;
        rightClickHideEnabled = false;
    };

    function returnToLobby() {
        pageDisplay.showLoadingPage();
        setTimeout(function () {
            return pageDisplay.loadLobbyPage();
        }, 100);
    }

    $scope.$on(Events.GAME_MODEL_CREATED, function (event, game) {
        pageDisplay.shouldKillGame = false;
        timeLog("Game model created, loading images.");
        var a = gameAssets;
        var backgroundIndex = preferences.getUserPrefValue(UserPrefIds.PREFERRED_BACKGROUND);
        if (backgroundIndex > 0) {
            a.push(backgroundAssets[backgroundIndex - 1]);
        }
        Preloader.loadAll(a, function () {
            timeLog("Loaded " + a.length + " images, now showing game page.");
            $rootScope.safeApply(pageDisplay.showGamePage());
        });
    });

    $scope.$on(Events.LOGIN_SUCCESS, function (event, loginSuccess) {
        pageDisplay.shouldShowKickButton = false;
        connection.hasKicked = false;
        if (loginSuccess.isReconnecting) {
            timeLog("Reconnecting...");
        } else {
            if (loginSuccess.previousReconnects > 0) pageDisplay.showLoadingPage();else pageDisplay.loadLobbyPage();
            safeDigest($scope);
        }
        pageDisplay.statusText = "";
    });
    $scope.$on(Events.CLEANUP_GAME, function () {
        return pageDisplay.loadScorePage();
    });

    $scope.$on(Events.RETURN_TO_LOBBY, function () {
        return returnToLobby();
    });

    $scope.$on(Events.RETURN_TO_LOGIN, function () {
        return returnToLoginPage();
    });

    var returnToLoginPage = function returnToLoginPage() {
        return $rootScope.safeApply(pageDisplay.showLoginPage());
    };

    function reset() {
        metaBroadcaster.send(Events.CLEAR_META_STATE);
        tableService.clearTable();
        returnToLoginPage();
    }

    var failureAlertExceptions = [FailureReasons.INVALID_SESSION_ID];

    function emailConfirmed() {
        window.location.href = "email-confirmed.html";
    }

    var successAlertExceptions = [SuccessDescriptions.KICK_SUCCESS, SuccessDescriptions.EMAIL_CONFIRMED, SuccessDescriptions.REMOVED_FROM_AUTOMATCH, SuccessDescriptions.SUCCESSFULLY_CONNECTED_NEW_SERVER, SuccessDescriptions.SUCCESSFULLY_CONNECTED_OLD_SERVER];

    var successAsStatusText = [SuccessDescriptions.SUCCESSFULLY_CONNECTED_NEW_SERVER, SuccessDescriptions.SUCCESSFULLY_CONNECTED_OLD_SERVER];

    $scope.$on(Events.TABLE_TERMINATED, function () {
        returnToLobby();
        $scope.$digest();
    });

    $scope.$on(Events.COMMAND_SUCCEEDED, function (event, reason) {
        pageDisplay.errorStatus = "";
        if (reason === SuccessDescriptions.EMAIL_CONFIRMED) emailConfirmed();
        if (successAlertExceptions.indexOf(reason) > -1) return;
        alert(LANGUAGE.getSuccessDescriptions[reason]);
    });

    pageDisplay.getLanguageClass = function () {
        return LANGUAGE.getName() + '-language';
    };

    pageDisplay.fullscreenAvailable = function () {
        return document.fullscreenEnabled;
    };
    pageDisplay.isFullscreen = function () {
        return document.fullscreenElement !== null;
    };
    pageDisplay.fullscreen = function () {
        if (!pageDisplay.isFullscreen()) {
            document.documentElement.requestFullscreen().then(function () {
                return screen.orientation.lock("landscape");
            });
        }
    };
    pageDisplay.toggleFullscreen = function () {
        if (pageDisplay.isFullscreen()) {
            document.exitFullscreen();
            screen.orientation.unlock();
        } else {
            document.documentElement.requestFullscreen().then(function () {
                return screen.orientation.lock("landscape");
            });
        }
    };

    pageDisplay.scoreFocusedTab = "score-focus";
    pageDisplay.scoreRulesHaveChanged = false;
    pageDisplay.setScoreFocusedTab = function (tab) {
        pageDisplay.scoreFocusedTab = tab;
        if (tab === "table-focus") pageDisplay.scoreRulesHaveChanged = false;
    };

    pageDisplay.getScoreTabNames = function () {
        return [LANGUAGE.getPhrase[Phrases.SCORE], LANGUAGE.getPhrase[Phrases.EDIT_TABLE]];
    };

    $scope.$on(Events.TABLE_RULE_CHANGED, function (e, r) {
        var ignoredRuleIds = [TableRuleIds.PLAYER_ORDER, TableRuleIds.MIN_PLAYERS, TableRuleIds.MAX_PLAYERS, TableRuleIds.MIN_RATING, TableRuleIds.MAX_RATING, TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT];
        if (!ignoredRuleIds.includes(r.rule.id)) {
            if (pageDisplay.scoreFocusedTab !== "table-focus") pageDisplay.scoreRulesHaveChanged = true;
        }
        $scope.$digest();
    });

    pageDisplay.statusText = "";
    pageDisplay.setStatusText = function (text, time) {
        pageDisplay.statusText = text;
        safeDigest($scope);
        setTimeout(function () {
            pageDisplay.statusText = "";
            safeDigest($scope);
        }, time);
    };

    $scope.$on(Events.COMMAND_FAILED, function (event, failure) {
        pageDisplay.setStatusText(failure.toTranslatedString(), 4000);
    });

    $scope.$on(Events.COMMAND_SUCCEEDED, function (event, success) {
        console.log("Command succeeded: " + success);
        if (successAsStatusText.indexOf(success) > -1) {
            var text = LANGUAGE.getSuccessDescriptions[success];
            pageDisplay.setStatusText(text, 2000);
        }
    });
}]);