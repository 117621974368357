"use strict";

var assets = {
    imageExtensions: ["png", "jpg", "gif"],
    fontExtensions: ["ttf", "otf", "ttc", "woff"],
    jsonExtensions: ["json"],
    audioExtensions: ["mp3", "ogg", "wav", "webm"],

    load: function load(sources) {
        return Promise.all(sources.map(this.loadSource.bind(this)));
    },

    loadSource: function loadSource(source) {
        var extension = source.split(".").pop();
        if (this.imageExtensions.indexOf(extension) !== -1) {
            return this.loadImage(source);
        } else if (this.fontExtensions.indexOf(extension) !== -1) {
            return this.loadFont(source);
        } else if (this.audioExtensions.indexOf(extension) !== -1) {
            return this.loadSound(source);
        } else {
            console.log("File type not recognized: " + source);
        }
    },

    loadImage: function loadImage(source) {
        if (source.indexOf('7B') > -1) throw new Error("Trying to load weird source.");
        if (isUndefined(this[source])) {
            var image = new Image();
            this[source] = new Promise(function (resolve) {
                image.addEventListener("load", resolve, false);
                image.src = source;
            });
        }
        return this[source];
    },

    loadFont: function loadFont(source) {
        var fontFamily = source.split("/").pop().split(".")[0];
        var newStyle = document.createElement("style");
        var fontFace = "@font-face {font-family: '" + fontFamily + "'; src: url('" + source + "');}";
        newStyle.appendChild(document.createTextNode(fontFace));
        document.head.appendChild(newStyle);
    },

    loadSound: function loadSound(source) {
        // console.log("loadSound called – see Chapter 10 for details");
    }
};