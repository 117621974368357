"use strict";

!function (a, b, c) {
  "use strict";
  function d() {
    this.development = function (a) {
      return g = a || g, this;
    }, this.$get = ["$window", "$log", function (a, d) {
      var e = { VERSION: a.Raven ? a.Raven.VERSION : "development", TraceKit: a.Raven ? a.Raven.TraceKit : "development", captureException: function captureException(b, c) {
          g ? d.error("Raven: Exception ", b, c) : a.Raven.captureException(b, c);
        }, captureMessage: function captureMessage(b, c) {
          g ? d.error("Raven: Message ", b, c) : a.Raven.captureMessage(b, c);
        }, setUser: function setUser(b) {
          g ? d.info("Raven: User ", b) : a.Raven.setUser ? a.Raven.setUser(b) : a.Raven.setUserContext && a.Raven.setUserContext(b);
        }, setUserContext: function setUserContext(b) {
          g ? d.info("Raven: User ", b) : a.Raven.setUserContext ? a.Raven.setUserContext(b) : a.Raven.setUser && a.Raven.setUser(b);
        }, lastException: function lastException() {
          g ? d.error("Raven: Last Exception") : a.Raven.lastException();
        }, setExtraContext: function setExtraContext(b) {
          g ? d.info("Raven: Extra Context ", b) : a.Raven.setExtraContext(b);
        }, setRelease: function setRelease(b) {
          g ? d.info("Raven: Release Context ", b) : a.Raven.setRelease(b);
        }, isSetup: function isSetup() {
          return a.Raven.isSetup();
        }, setDataCallback: function setDataCallback(b) {
          return a.Raven.setDataCallback(b);
        }, setShouldSendCallback: function setShouldSendCallback(b) {
          return a.Raven.setShouldSendCallback(b);
        }, setTagsContext: function setTagsContext(b) {
          g ? d.info("Raven: Tags Context ", b) : a.Raven.setTagsContext(b);
        }, context: function context(a, d, e) {
          var f = this;return b.isFunction(a) && (e = d || [], d = a, a = c), f.wrap(a, d).apply(f, e);
        }, wrap: function wrap(a, d) {
          function e() {
            for (var b = [], c = arguments.length; c--;) {
              b[c] = f.wrap(a, arguments[c]);
            }try {
              return d.apply(this, b);
            } catch (e) {
              f.captureException(e, a);
            }
          }var f = this;if (b.isUndefined(d) && !b.isFunction(a)) return a;if (b.isFunction(a) && (d = a, a = c), !b.isFunction(d)) return d;if (d.__raven__) return d;for (var g in d) {
            d.hasOwnProperty(g) && (e[g] = d[g]);
          }return e.__raven__ = !0, e;
        } };return e;
    }];
  }function e(a) {
    a.decorator("$exceptionHandler", ["$delegate", "$raven", "$injector", f]);
  }function f(a, c, d) {
    function e(e, g) {
      f = f || d.get("$location");var h = { culprit: f.absUrl(), extra: { exception: e, cause: g } };if (e instanceof Error) c.captureException(e, h);else {
        var i = "";i = b.isString(e.message) ? e.message : b.isString(e) ? e : e.statusText || "", c.captureMessage(i, h);
      }a(e, g);
    }var f;return e;
  }var g = null;b.module("ngRaven", []).provider("$raven", d).provider("Raven", d).config(["$provide", e]), b.module("angular-raven", ["ngRaven"]);
}(undefined, angular);